import * as React from 'react';
import { Box } from 'rebass/styled-components';
import { Row } from '@/components/Row';
import content from '@/cms/home';
import { Title } from '@/components/Title';
import { Text } from '@/components/Text';

export const About = () => (
  <Box mt={['90px', '90px', 5]} mb={['90px', 5]}>
    <Row
      left={<Title>{content.about.title}</Title>}
      right={<Text fontSize={[3]}>{content.about.content}</Text>}
    />
  </Box>
);

import * as React from 'react';
import { Box, Heading, Flex } from 'rebass/styled-components';
import { projects } from '@/cms/projects';
import { ProjectTout } from '@/components/ProjectTout';
import home from '@/cms/home';
import { routes } from '@/routes';
import { styledTheme } from '@/styledTheme';
import ArrowLink from '@/components/ArrowLink';
import styled from 'styled-components';
import { Row } from '../Row';
import { Title } from '../Title';
import { ProjectShowcase } from '../ProjectShowcase';
import { TextAnimation } from '../TextAnimation';

export const OurWorks = () => (
  <Box mt={[0, 0, 5]} mb={['90px', '90px', 5]}>
    <TextAnimation>
      <Row
        mb={[4, 4, 5]}
        left={<Title mt={1}>{home.ourWorks.title}</Title>}
        right={
          <Heading
            fontSize={[5, 5, 6]}
            fontWeight={700}
            maxWidth={750}
            mt={[-1]}
            fontFamily={styledTheme.fonts.body}
          >
            {home.ourWorks.content}
          </Heading>
        }
      />
    </TextAnimation>
    <MobileWrapper>
      {projects.items.slice(0, 4).map((p) => (
        <ProjectTout {...p} isHome />
      ))}
    </MobileWrapper>
    <DesktopWrapper>
      <ProjectShowcase />
    </DesktopWrapper>

    <Row
      mt={[-3, -3, 4]}
      left={null}
      right={
        <Flex>
          <ArrowLink
            isLeft={false}
            href={routes.work}
            text={home.ourWorks.buttonText}
            fontFamily={styledTheme.fonts.heading}
          />
        </Flex>
      }
    />
  </Box>
);

const DesktopWrapper = styled.div`
  display: block;
  @media screen and (max-width: ${styledTheme
      .breakpoints[2]}) and (orientation: portrait) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${styledTheme
      .breakpoints[2]}) and (orientation: portrait) {
    display: block;
  }
`;

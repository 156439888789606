import React from 'react';

import { Layout } from '@/components/Layout';
import { Hero } from '@/components/home/Hero';
import { About } from '@/components/home/About';
import { WhatWeDo } from '@/components/home/WhatWeDo';
import { Clients } from '@/components/Clients';
import { CTA } from '@/components/CTA';
import { OurWorks } from '@/components/home/OurWorks';

export default () => (
  <Layout>
    <Hero />

    <About />

    <OurWorks />

    <WhatWeDo />

    <Clients />

    <CTA />
  </Layout>
);

import * as React from 'react';
import styled from 'styled-components';
import { Box, Flex, Heading } from 'rebass/styled-components';
import { styledTheme } from '@/styledTheme';
import { Text } from '@/components/Text';
import { projects } from '@/cms/projects';
import { Animated } from '@/components/Animated';
import { Link } from 'gatsby';
import { useWindowSize } from '@/hooks/useWindowSize';
import { ContentWrapper } from './ContentWrapper';

const getAnimatedProps = (index, imageIndex, isDesktop) => {
  const mapping = {
    0: {
      1: {
        from: {
          y: -100,
        },
        to: {
          y: 0,
        },
      },
      2: {
        from: {
          y: 0,
          x: 20,
        },
        to: {
          y: -17,
          x: -165,
        },
      },
      3: {
        from: {
          x: 700,
          y: 0,
        },
        to: {
          x: 800,
          y: 60,
        },
      },
    },
    1: {
      1: {
        from: { y: -100 },
        to: { y: 0 },
      },
      2: {
        from: {
          opacity: 0.1,
          y: 30,
          x: 0,
        },
        to: {
          opacity: 1,
          y: 30,
          x: -104,
        },
      },
      3: {
        from: {
          x: 30,
          y: 0,
        },
        to: {
          opacity: 1,
          x: 229,
          y: -65,
        },
      },
    },
    2: {
      1: {
        from: {
          y: -100,
        },
        to: {
          y: 0,
        },
      },
      2: {
        from: {
          y: 50,
          x: 235,
        },
        to: {
          opacity: 1,
          y: 50,
          x: 340,
        },
      },
      3: {
        from: {
          opacity: 1,
          x: -100,
          y: 0,
        },
        to: {
          opacity: 1,
          x: -282,
          y: -37,
        },
      },
    },
    3: {
      1: {
        from: { y: -100 },
        to: { y: 0 },
      },
      2: {
        from: {
          y: 30,
          x: 0,
        },
        to: {
          y: 30,
          x: -60,
        },
      },
      3: {
        from: {
          x: 30,
          y: 0,
        },
        to: {
          opacity: 1,
          x: 148,
          y: -43,
        },
      },
    },
  };
  const tabletMapping = {
    0: {
      1: {
        from: {
          y: -100,
        },
        to: {
          y: 0,
        },
      },
      2: {
        from: {
          y: 0,
          x: 20,
        },
        to: {
          y: -30,
          x: -40,
        },
      },
      3: {
        from: {
          x: 600,
          y: 0,
        },
        to: {
          x: 690,
          y: 60,
        },
      },
    },
    1: {
      1: {
        from: { y: -100 },
        to: { y: 0 },
      },
      2: {
        from: {
          opacity: 0.1,
          y: 30,
          x: 0,
        },
        to: {
          opacity: 1,
          y: 30,
          x: -118,
        },
      },
      3: {
        from: {
          width: 612,
          x: 124,
          y: 0,
        },
        to: {
          opacity: 1,
          width: 612,
          x: 164,
          y: -30,
        },
      },
    },
    2: {
      1: {
        from: {
          y: -100,
        },
        to: {
          y: 0,
        },
      },
      2: {
        from: {
          y: 50,
          x: 235,
        },
        to: {
          opacity: 1,
          y: 50,
          x: 340,
        },
      },
      3: {
        from: {
          width: 712,
          opacity: 1,
          x: 0,
          y: 0,
        },
        to: {
          width: 712,
          opacity: 1,
          x: -40,
          y: -37,
        },
      },
    },
    3: {
      1: {
        from: { y: -100 },
        to: { y: 0 },
      },
      2: {
        from: {
          y: 30,
          x: 0,
        },
        to: {
          y: 30,
          x: -118,
        },
      },
      3: {
        from: {
          x: 0,
          y: 0,
        },
        to: {
          opacity: 1,
          x: 40,
          y: -30,
        },
      },
    },
  };
  return isDesktop
    ? {
        ...mapping[index][imageIndex],
        toScrollTrigger: {
          scrub: true,
          toggleActions: 'play pause resume reset',
        },
      }
    : {
        ...tabletMapping[index][imageIndex],
        toScrollTrigger: {
          scrub: true,
          toggleActions: 'play pause resume reset',
        },
      };
};

export const ProjectShowcase = () => {
  const windowSize = useWindowSize();
  const isDesktop = windowSize.width > 1200;
  return (
    <Box maxWidth="100vw" style={{ overflow: 'hidden' }}>
      <ContentWrapper maxWidth={1000}>
        <Wrapper>
          {projects.items
            .filter((p, i) => i < 4)
            .map(({ title, scope, img1, img2, img3, url }, index) => (
              <ProjectWrapper>
                {windowSize.width && (
                  <ImagesWrapper height={600} index={index}>
                    <Animated {...getAnimatedProps(index, 1, isDesktop)}>
                      <Box as={Link} to={`/work/${url}`}>
                        {img1}
                      </Box>
                    </Animated>
                    <Animated {...getAnimatedProps(index, 2, isDesktop)}>
                      <Box>{img2}</Box>
                    </Animated>
                    <Animated {...getAnimatedProps(index, 3, isDesktop)}>
                      <Box>{img3}</Box>
                    </Animated>
                  </ImagesWrapper>
                )}
                <Animated
                  from={{ opacity: 0, y: 30 }}
                  to={{ opacity: 1, y: 0 }}
                >
                  <Box mt={['20px', '20px', 3]}>
                    <ProjectTitle as={Link} to={`/work/${url}`}>
                      {title}
                    </ProjectTitle>
                    <Flex>
                      <Text
                        color={styledTheme.colors.secondary}
                        fontFamily={styledTheme.fonts.heading}
                        fontSize={['14px', '14px', '16px']}
                        mt={['6px', '6px', '10px']}
                        lineHeight={['24px']}
                      >
                        {scope}
                      </Text>
                    </Flex>
                  </Box>
                </Animated>
              </ProjectWrapper>
            ))}
        </Wrapper>
      </ContentWrapper>
    </Box>
  );
};

const Wrapper = styled(Box)`
  > *:nth-child(2n) {
    padding-left: 200px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints[3]}) {
      padding-left: 100px;
    }
  }
`;

const ImagesWrapper = styled(Box)<{ index: number }>`
  padding-top: 100px;
  //position: relative;
  > * {
    position: absolute;
  }

  > * div {
    position: relative;
    bottom: 0;
  }

  .animated:nth-child(1) div {
    z-index: 3;
  }

  .animated:nth-child(2) div {
    z-index: 2;
  }

  .animated:nth-child(3) div {
    z-index: 1;
  }

  .animated:nth-child(1) {
    z-index: 3;
  }

  .animated:nth-child(2) {
    z-index: 2;
  }

  .animated:nth-child(3) {
    z-index: 1;
  }
`;

const ProjectWrapper = styled(Box)`
  margin-bottom: 180px;

  &:last-of-type {
    margin-bottom: 60px;
  }
`;

const ProjectTitle = styled(Heading)`
  text-decoration: none;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: ${styledTheme.colors.primary};
  font-family: ${styledTheme.fonts.body};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    font-size: 20px;
    line-height: 20px;
  }
`;

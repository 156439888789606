import * as React from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { HeroAnimation } from '@/components/home/HeroAnimation';
import { HeroTitle } from '@/components/HeroTitle';
import { TextAnimation } from '@/components/TextAnimation';
import { Animated } from '@/components/Animated';
import { ContentWrapper } from '../ContentWrapper';

export const Hero = () => (
  <Wrapper>
    <HeroAnimation />
    <HeroText />
  </Wrapper>
);

const Wrapper = styled(Box)`
  position: relative;
  overflow-x: hidden;
`;

const textAnimationDefaultProps = {
  style: { opacity: 0 },
  from: { opacity: 0 },
  toScrollTrigger: {
    once: false,
  },
};
const HeroText = () => (
  <ContentWrapper>
    <TitleWrapper>
      <TextAnimation
        to={{ opacity: 1, delay: 0.5, duration: 1 }}
        {...textAnimationDefaultProps}
      >
        <HeroTitle>We love data.</HeroTitle>
      </TextAnimation>
      <TextAnimation
        to={{ opacity: 1, delay: 1, duration: 1 }}
        {...textAnimationDefaultProps}
      >
        <HeroTitle>We love stories.</HeroTitle>
      </TextAnimation>
      <TextAnimation
        to={{ opacity: 1, delay: 1.5, duration: 1 }}
        {...textAnimationDefaultProps}
      >
        <HeroTitle>
          We are{' '}
          <Animated
            to={{ opacity: 1, delay: 1.5 }}
            style={{ display: 'inline', opacity: 0 }}
            from={{ opacity: 0 }}
            toScrollTrigger={{
              once: false,
              toggleActions: 'play none none none',
            }}
          >
            <span>skalar</span>.
          </Animated>
        </HeroTitle>
      </TextAnimation>
    </TitleWrapper>
  </ContentWrapper>
);

const TitleWrapper = styled(Box)`
  position: absolute;
  top: 67px;
  margin-left: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    top: 210px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-left: 85px;
  }
`;

import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import SimplexNoise from 'simplex-noise';
import { useWindowSize } from '@/hooks/useWindowSize';
import { styledTheme } from '@/styledTheme';
import useIsInViewport from 'use-is-in-viewport';
// import DatGui, { DatNumber } from 'react-dat-gui';
import styled from 'styled-components';

const TAU = Math.PI * 2;
const simplex = new SimplexNoise();
const xnoise = 0.3;

const ynoise = 1.61;

const normalize = (value, min = -1, max = 1) => (value - min) / (max - min);
const normalizeRadius = d3.scaleLinear().domain([0, 1]).range([0.01, 1]);
export const HeroAnimation = () => {
  const wrapperRef = useRef(null);
  const ref = useRef(null);
  const windowSize = useWindowSize();
  const [isInViewport, wrappedTargetRef] = useIsInViewport({
    target: wrapperRef,
  });
  const noise = simplex.noise3D.bind(simplex);
  const isClient = windowSize.width > 0;
  const pixelRatio = windowSize.devicePixelRatio;
  const animationRef = useRef(null);
  const width = pixelRatio * windowSize.width || 1200;
  const height = pixelRatio * (width > 768 ? 480 : 250);
  const [start, setStart] = useState(null);

  const [opts, setOpts] = useState({
    frequency: 5,
    speed: 130,
    // speed: 20,
    spaceBetweenDots: 20,
    spaceBetweenDotsX: 70,
    spaceBetweenDotsY: 40,
    margin: 50,
    // animationSlowdown: 3500,
    animationSlowdown: 0,
    size: 8,
    center: -120,
    // center: height,
    verticalMove: 0,
    horizontalMove: 80,
    points: null,
    getCenterForce: (y, _center, _verticalMove) =>
      (1 - Math.abs(y - _center) / _center) /** * 2 */ * _verticalMove,
  });

  useEffect(() => {
    renderAnimation();
    // updateSliders();
  }, [windowSize.width, opts, isInViewport]);

  useEffect(() => {
    const canvas = ref.current;
    const context = canvas.getContext('2d');
    context.scale(pixelRatio, pixelRatio);
  }, []);

  const draw = (context) => {
    const {
      margin,
      spaceBetweenDotsX,
      spaceBetweenDotsY,
      animationSlowdown,
      size,
      center,
      verticalMove,
      horizontalMove,
      getCenterForce,
      frequency,
      speed,
    } = opts;

    const points = [];

    for (let i = -margin; i < width + margin; i += spaceBetweenDotsX) {
      for (let j = -margin; j < height + margin; j += spaceBetweenDotsY) {
        points.push([i, j]);
      }
    }

    if (!context) return;

    // let time = performance.now() - animationSlowdown;
    // const now = performance.now() - animationSlowdown;
    const time = performance.now() - animationSlowdown - start;
    context.fillStyle = 'white';
    context.fillRect(0, 0, width, height);

    let radius = 1;
    const freq = 0.001 * frequency;
    const z = time * 0.000001 * speed;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < points.length; ++i) {
      let [x, y] = points[i];

      const n = noise(x * freq * xnoise - z, y * freq * ynoise, z);
      const t = n * Math.PI;
      const nn = normalize(n);
      const nnn = normalizeRadius(nn);

      radius = Math.max(size * nnn, 1);

      const slowdownRatio =
        (time / 2 + 0.5 * animationSlowdown) / animationSlowdown;
      const mod = slowdownRatio < 1 ? Math.max(slowdownRatio, 0) ** 2 : 1;
      // const move = mod * Math.cos(t);
      const move = Math.cos(t);
      x += move * horizontalMove;
      y += move * 2 * getCenterForce(y, center, verticalMove);

      context.beginPath();
      context.moveTo(x + radius, y);
      context.arc(x, y, radius, 0, TAU);
      context.fillStyle = styledTheme.colors.divider;
      context.fill();
      context.closePath();
    }
  };

  const renderAnimation = (resetRenderAnimation = false) => {
    if (!isClient) {
      return null;
    }
    if (!start || resetRenderAnimation) {
      setStart(performance.now());
    }

    const canvas = ref.current;
    const context = canvas.getContext('2d');

    const render = (_, reset) => {
      if (!isInViewport) {
        window.cancelAnimationFrame(animationRef.current);
        return;
      }
      if (reset) {
        window.cancelAnimationFrame(animationRef.current);

        setStart(performance.now());
        draw(context);
        animationRef.current = window.requestAnimationFrame(render);
      } else {
        draw(context);
        animationRef.current = window.requestAnimationFrame(render);
      }
    };

    render(undefined, resetRenderAnimation);

    return () => {
      window.cancelAnimationFrame(animationRef.current);
    };
  };

  // , [windowSize.width]);

  return (
    <div ref={wrappedTargetRef} style={{ position: 'relative' }}>
      <canvas
        ref={ref}
        width={width}
        // height={height * pixelRatio}
        height={height}
        style={{
          width: '100%',
          height: `${height / pixelRatio}px`,
        }}
      />
      <div style={{ position: 'fixed', top: 20, right: 20 }}>
        <Wrapper>
          {/* <DatGui */}
          {/*  data={opts} */}
          {/*  onUpdate={(values) => { */}
          {/*    window.cancelAnimationFrame(animationRef.current); */}

          {/*    updateSliders(); */}
          {/*    const spacing = {}; */}
          {/*    if (values.spaceBetweenDots !== opts.spaceBetweenDots) { */}
          {/*      spacing.spaceBetweenDotsX = values.spaceBetweenDots; */}
          {/*      spacing.spaceBetweenDotsY = values.spaceBetweenDots; */}
          {/*    } */}
          {/*    setOpts({ ...values, ...spacing }); */}
          {/*  }} */}
          {/* > */}
          {/*  <DatNumber */}
          {/*    label="spacing" */}
          {/*    path="spaceBetweenDots" */}
          {/*    min={15} */}
          {/*    max={100} */}
          {/*    step={1} */}
          {/*  /> */}
          {/*  <DatNumber */}
          {/*    label="spacing x" */}
          {/*    path="spaceBetweenDotsX" */}
          {/*    min={15} */}
          {/*    max={100} */}
          {/*    step={1} */}
          {/*  /> */}
          {/*  <DatNumber */}
          {/*    label="spacing y" */}
          {/*    path="spaceBetweenDotsY" */}
          {/*    min={15} */}
          {/*    max={100} */}
          {/*    step={1} */}
          {/*  /> */}
          {/*  <DatNumber */}
          {/*    label="frequency" */}
          {/*    path="frequency" */}
          {/*    min={1} */}
          {/*    max={30} */}
          {/*    step={0.1} */}
          {/*  /> */}
          {/*  <DatNumber label="speed" path="speed" min={1} max={500} step={1} /> */}
          {/*  <DatNumber label="size" path="size" min={1} max={30} step={1} /> */}
          {/*  <DatNumber */}
          {/*    label="center" */}
          {/*    path="center" */}
          {/*    min={(-1 / 2) * height} */}
          {/*    max={(1 / 2) * height} */}
          {/*    step={10} */}
          {/*  /> */}
          {/*  <DatNumber */}
          {/*    label="move x" */}
          {/*    path="horizontalMove" */}
          {/*    min={-200} */}
          {/*    max={200} */}
          {/*    step={1} */}
          {/*  /> */}
          {/*  <DatNumber */}
          {/*    label="move y" */}
          {/*    path="verticalMove" */}
          {/*    min={-200} */}
          {/*    max={200} */}
          {/*    step={1} */}
          {/*  /> */}
          {/* </DatGui> */}
        </Wrapper>
      </div>
    </div>
  );
};

// const updateSliders = () => {
//   const x = Array.from(document.querySelectorAll('span.slider'));
//   x.forEach((e) => {
//     const now = e.getAttribute('aria-valuenow');
//     const min = e.getAttribute('aria-valuemin');
//     const max = e.getAttribute('aria-valuemax');
//     const range = max - min;
//     const mod = min < 0 ? -min : 0;
//     const value = ((Math.abs(now) + mod) / range) * 100;
//
//     e.style.backgroundRepeat = 'no-repeat';
//     e.style.backgroundImage = `
//                   linear-gradient(
//                     to right,
//                     black 0%,
//                     black ${value}%,
//                     transparent ${value + 1}%
//                 )`;
//   });
// };

const Wrapper = styled.div`
  & > [class^='leva-c-'] {
    top: 100px !important;
  }

  input {
    background-color: transparent;
    border: none;
    margin-left: 10px;
  }
  label {
    min-width: 200px;
  }
  label .label-text {
    min-width: 100px;
  }
  margin-top: 80px;
  span.slider {
    border: 1px solid black;
    display: flex;
    width: 100px !important;
    height: 20px;
  }

  li {
    list-style: none;
  }
  li label {
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      width: 50px !important;
    }
  }
`;
